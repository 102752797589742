import React from 'react';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import styles from './Disclaimer.module.less';
import mobile from './mobile.png';
import pc from './pc.png';

export default function Disclaimer(){
  return (
    <div className={styles.root}>
      <MediaQuery minWidth={992}>
        {
          (matches) => <Link to="/announcements/7"><img src={matches ? pc : mobile} alt= "" /></Link>
        }
      </MediaQuery>
    </div>
  )
}