import React, { PureComponent } from 'react';
import { Button, Input, Row, Col, Form } from 'antd';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import LinkIcon from 'react-feather/dist/icons/external-link';
import HomeIcon from 'react-feather/dist/icons/home';
import { TrackerContext, AffiliateFeeNotice } from '../../common';
import { WorkspacePage, CpRow, Copy2Clipboard, RsLabel, UnselectableInput } from '../../components';
import CoupangSRPImage from '../images/coupang_srp_n.png';
import './LinkToAnyPage.less';
import Api from '../api';
import SnsSharer from '../../components/SnsSharer/SnsSharer';
import Disclaimer from '../components/Disclaimer/Disclaimer';

const CoupangLink = 'https://www.coupang.com';
const DefaultUrl = 'https://www.coupang.com/np/goldbox';

export default Form.create()(
    injectIntl(
        connect((state) => ({ currentSubId: state.subId.currentSubId }))(
            class LinkToAnyPage extends PureComponent {
                static contextType = TrackerContext;
                constructor(props) {
                    super(props);
                    this.state = {
                        loading: false,
                        shouldGenerate: true,
                        url: DefaultUrl,
                        trackingUrl: '',
                        type: '', // short url type,
                        title: '',
                        description: '',
                        imageUrl: '',
                        recommendedCategories: [],
                    };
                }

                componentDidUpdate(prevProps) {
                    if (prevProps.currentSubId !== this.props.currentSubId) {
                        this.setState({
                            shouldGenerate: true,
                            trackingUrl: '',
                        });
                    }
                }

                componentDidMount() {
                    this.setState({ loading: true }, () => {
                        Api.getRecommendedCategoriesForLinkToAnyPage()
                            .then((res) => {
                                this.setState({
                                    recommendedCategories: res.data.data,
                                });
                            })
                            .finally(() => {
                                this.setState({ loading: false });
                            });
                    });
                }

                handleUrlChange = (e) => {
                    let value = e.target.value;
                    // const { form } = this.props;
                    setTimeout(() => {
                        this.setState({
                            shouldGenerate: true,
                            url: value.trim(),
                            trackingUrl: '',
                        });
                        // form.setFieldsValue({ url: value.trim() });
                    }, 0);
                };

                handleConvertUrl = () => {
                    const { intl, form } = this.props;
                    const { shouldGenerate, url } = this.state;
                    if (shouldGenerate) {
                        form.validateFields((err) => {
                            if (!err) {
                                this.setState({ loading: true, prevUrl: url, trackingUrl: '' }, () => {
                                    Api.convertCoupangUrlToTrackingUrl(url)
                                        .then((res) => {
                                            const { type, shortUrl, title, description, imageUrl } = res.data.data;
                                            this.context.trackEvent({
                                                action: 'generate_link_to_any_page_short_url',
                                                value: type,
                                            });
                                            this.setState({
                                                shouldGenerate: false,
                                                trackingUrl: shortUrl,
                                                type,
                                                title,
                                                description,
                                                imageUrl,
                                            });
                                        })
                                        .catch((err) => {
                                            const { rCode } = err.data;
                                            if (rCode === '702' || rCode === '703') {
                                                form.setFields({
                                                    url: {
                                                        errors: [
                                                            new Error(
                                                                intl.formatMessage({
                                                                    id: 'LINK_TO_ANY_PAGE_NOT_SUPPORTED_PRODUCT',
                                                                })
                                                            ),
                                                        ],
                                                    },
                                                });
                                            } else {
                                                form.setFields({
                                                    url: {
                                                        errors: [
                                                            new Error(
                                                                intl.formatMessage({
                                                                    id: 'LINK_TO_ANY_PAGE_NOT_SUPPORTED_URL',
                                                                })
                                                            ),
                                                        ],
                                                    },
                                                });
                                            }
                                        })
                                        .finally(() => {
                                            this.setState({ loading: false });
                                        });
                                });
                            }
                        });
                    }
                };

                onCopyTrackingUrl = () => {
                    const { type } = this.state;
                    this.context.trackEvent({ action: 'copy_link_to_any_page_short_url', value: type });
                };

                handleCategoryClick = (url = 'https://www.coupang.com/') => {
                    const { form } = this.props;
                    this.setState({ shouldGenerate: true, url, trackingUrl: '' });
                    form.setFieldsValue({ url });
                };

                render() {
                    const {
                        intl,
                        form: { getFieldDecorator },
                    } = this.props;
                    const breadcrumb = [
                        {
                            title: intl.formatMessage({ id: 'AFFILIATE_HOME' }),
                            link: '/affiliate',
                            icon: <HomeIcon size={14} />,
                        },
                        { title: intl.formatMessage({ id: 'LINK_TO_ANY_PAGE' }) },
                    ];

                    const {
                        loading,
                        recommendedCategories,
                        url,
                        trackingUrl,
                        title,
                        description,
                        imageUrl,
                    } = this.state;

                    return (
                        <WorkspacePage
                            className="link-to-any-page"
                            breadcrumb={breadcrumb}
                            title={intl.formatMessage({ id: 'LINK_TO_ANY_PAGE' })}
                            subTitle={''}
                            loading={loading}
                            withSubId={true}>
                            <CpRow>
                                <Row>
                                    <Col span={24} className="align-center">
                                        <div className="align-left link-to-any-page-banner">
                                            <Row type="flex" align="middle">
                                                <Col md={24} lg={12} className="l2a-description">
                                                    <p>쿠팡에서 제품 상세 페이지, 검색결과, 기획전 URL을 복사해</p>
                                                    <p>쉽게 파트너스 링크로 만들어보세요​</p>
                                                    <p className="color-accent">
                                                        쿠팡에서 원하는 페이지 찾아보기{' '}
                                                        <a href={CoupangLink} target="_blank" rel="noopener noreferrer">
                                                            <LinkIcon color="#3c3d3f" size={18} />
                                                        </a>
                                                    </p>
                                                </Col>
                                                <Col md={24} lg={12} className="l2a-image">
                                                    <img src={CoupangSRPImage} alt="" />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </CpRow>

                            <CpRow className="code-generation-container is-flex bg-grey">

                                <div className="pane">
                                    <h2 className="pane-title">쿠팡 URL</h2>
                                    {recommendedCategories.length > 0 && (
                                        <div className="recommended-links">
                                            <label>
                                                {intl.formatMessage({ id: 'LINK_TO_ANY_PAGE_RECOMMENDED_LINKS' })}
                                            </label>
                                            <span className="link-tags">
                                                {recommendedCategories.map((x) => (
                                                    <Button
                                                        size="small"
                                                        key={x.key}
                                                        onClick={() => this.handleCategoryClick(x.coupangUrl)}>
                                                        <RsLabel text={x} />
                                                    </Button>
                                                ))}
                                            </span>
                                        </div>
                                    )}

                                    <div className="manual-link">
                                        <Form>
                                            <Form.Item label={null}>
                                                {getFieldDecorator('url', {
                                                    initialValue: url,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({
                                                                id: 'VAL_MSG_THIS_FIELD_IS_REQUIRED',
                                                            }),
                                                        },
                                                        {
                                                            pattern: /^https?:\/\/(\w+\.)*coupang.com/,
                                                            message: intl.formatMessage({
                                                                id: 'LINK_TO_ANY_PAGE_NOT_SUPPORTED_URL',
                                                            }),
                                                        },
                                                    ],
                                                    validateTrigger: 'onBlur',
                                                })(
                                                    <Input
                                                        className="isolate-button"
                                                        size="large"
                                                        placeholder={null}
                                                        onChange={this.handleUrlChange}
                                                        onPressEnter={this.handleConvertUrl}
                                                        suffix={
                                                            <Button
                                                                className="lg"
                                                                type="primary"
                                                                onClick={this.handleConvertUrl}>
                                                                {intl.formatMessage({
                                                                    id: 'LINK_TO_ANY_PAGE_CREATE_LINK',
                                                                })}
                                                            </Button>
                                                        }
                                                    />
                                                )}
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                                {
                                    <div style={{ display: trackingUrl ? 'block' : 'none' }}>
                                        <Disclaimer />
                                        <div className="pane" >
                                            <h2 className="pane-title">파트너스 URL</h2>
                                            <div className="tracking-url">
                                                <UnselectableInput
                                                    className="tracking-url-input"
                                                    value={trackingUrl}
                                                    disabled={true}
                                                    size="large"
                                                />
                                                <div className="tracking-url-controls">
                                                    <SnsSharer
                                                        title={title}
                                                        description={description}
                                                        imageUrl={imageUrl}
                                                        link={trackingUrl}
                                                    />
                                                    <Copy2Clipboard
                                                        content={trackingUrl}
                                                        popOverTimeout={1000}
                                                        popOverText={intl.formatMessage({ id: 'COPIED_DISCLAIMER' })}>
                                                        <Button
                                                            className="lg tracking-url-controls-main"
                                                            onClick={this.onCopyTrackingUrl}>
                                                            {intl.formatMessage({ id: 'LINK_GENERATION_COPY_URL' })}
                                                        </Button>
                                                    </Copy2Clipboard>
                                                </div>
                                            </div>
                                            <AffiliateFeeNotice />
                                        </div>
                                    </div>
                                }
                            </CpRow>
                        </WorkspacePage>
                    );
                }
            }
        )
    )
);
