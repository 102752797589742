import React, { Component } from 'react';
// import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import qs from 'qs';
import { Button, Checkbox } from 'antd';
import MediaQuery from 'react-responsive';
import HomeIcon from 'react-feather/dist/icons/home';
import { AffiliateFeeNotice } from '../../common';
import {
    WorkspacePage,
    CpRow,
    Steps,
    HTMLCodeViewer,
    Copy2Clipboard,
    UnselectableInput,
    SnsSharer,
} from '../../components';
import { Product } from '../components';
import { fetchLinkGenerationInitData, generateBannerImage } from '../actions';

import { TrackerContext } from '../../common';
import Disclaimer from '../components/Disclaimer/Disclaimer';
import './LinkGeneration.css';

const mapStateToProps = (state) => ({
    ...state.productlink.link,
    staticImageCDN: state.config.staticImageCDN,
    loading: state.productlink.loading,
    imageCdnUrl: state.config.imageCdnUrl,
    currentSubId: state.subId.currentSubId,
});

const mapDispatchToProps = {
    fetchLinkGenerationInitData,
    generateBannerImage,
};

class LinkGeneration extends Component {
    static contextType = TrackerContext;
    static propTypes = {
        fetchLinkGenerationInitData: PropTypes.func,
        shortUrl: PropTypes.string,
        html: PropTypes.string,
    };

    constructor(props) {
        super(props);
        const queryString = qs.parse(props.location.search.substr(1));
        const { group, product: productQueryString } = queryString;
        const product = Product.parse(productQueryString);

        this.state = {
            group,
            border: true,
            codeOption: 'iframe',
            product,
            codeCopied: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentSubId !== this.props.currentSubId || prevState.codeOption !== this.state.codeOption) {
            const { fetchLinkGenerationInitData } = this.props;
            const { group, product, codeOption } = this.state;
            if (codeOption === 'iframe') {
                if (fetchLinkGenerationInitData) {
                    fetchLinkGenerationInitData(group, product);
                }
            } else if (codeOption === 'image') {
                this.generateImage();
            }
        }
    }

    componentDidMount() {
        const { fetchLinkGenerationInitData } = this.props;
        const { group, product } = this.state;

        if (fetchLinkGenerationInitData) {
            fetchLinkGenerationInitData(group, product);
        }
    }

    copyUrlToClipboard = () => {
        this.context.trackEvent({ action: 'copy_short_url', value: 'shorturl' });
    };

    copyHtmlToClipboard = () => {
        if (this.htmlRef && this.htmlRef.textAreaRef) {
            this.htmlRef.textAreaRef.select();
            document.execCommand('Copy');
        }
    };

    toggleBorder = (e) => {
        this.setState(
            {
                border: e.target.checked,
            },
            () => {
                if (this.state.codeOption === 'image') {
                    this.generateImage();
                }
            }
        );
    };

    onCodeFormatChange = (e) => {
        this.setState({
            codeOption: e.target.value,
            codeCopied: false,
        });

        switch (e.target.value) {
            case 'iframe':
                this.context.trackEvent({ action: 'generaltag' });
                break;
            case 'image':
                this.context.trackEvent({ action: 'blogtag' });
                break;
            default:
                break;
        }
    };

    onCopyCode = () => {
        let { codeOption } = this.state;
        switch (codeOption) {
            case 'iframe':
                this.context.trackEvent({ action: 'copy_link_code', value: 'generaltag' });
                break;
            case 'image':
                // this.generateImage();
                this.context.trackEvent({ action: 'copy_link_code', value: 'blogtag' });
                break;
            default:
                break;
        }
        this.setState({ codeCopied: true });
    };

    generateImage() {
        const { generateBannerImage, intl } = this.props;
        const { group, product } = this.state;
        if (generateBannerImage) {
            generateBannerImage(group, product, {
                border: this.state.border,
                shoppingButtonText: intl.formatMessage({ id: 'SHOPPING' }),
            });
        }
    }

    render() {
        const {
            intl,
            loading,
            shortUrl,
            iframeHtmlTagBorderOff,
            iframeHtmlTagBorderOn,
            imageHtmlTag,
            title,
            description,
            imageUrl,
        } = this.props;
        const { codeOption, border, codeCopied } = this.state;

        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'PRODUCT_LINK' }), link: '/affiliate/ws/link' },
            { title: intl.formatMessage({ id: 'PRODUCT_LINK_GENERATION' }) },
        ];
        const steps = [
            intl.formatMessage({ id: 'PRODUCT_LINK_STEP1' }),
            intl.formatMessage({ id: 'PRODUCT_LINK_STEP2' }),
            intl.formatMessage({ id: 'PRODUCT_LINK_STEP3' }),
        ];

        let htmlString = '';
        if (codeOption === 'iframe') {
            if (border) {
                htmlString = iframeHtmlTagBorderOn;
            } else {
                htmlString = iframeHtmlTagBorderOff;
            }
        } else {
            htmlString = imageHtmlTag;
        }

        const codeOptions = [
            { key: 'iframe', title: intl.formatMessage({ id: 'LINK_GENERATION_CODE_TYPE_IFRAME' }) },
            { key: 'image', title: intl.formatMessage({ id: 'LINK_GENERATION_CODE_TYPE_IMAGE' }) },
        ];

        return (
            <WorkspacePage
                className="link-generation"
                breadcrumb={breadcrumb}
                title={intl.formatMessage({ id: 'PRODUCT_LINK_GENERATION' })}
                withSubId={true}
                loading={loading}>
                <CpRow>
                    <section className="search-bar-banner align-center">
                        <div className="ft-sub-headline-bold">
                            <span>{intl.formatMessage({ id: 'PRODUCT_LINK_SLOGAN' })}</span>
                        </div>
                        <div className="link-generation-steps-indicator">
                            <Steps size="small" current={2} steps={steps} />
                        </div>
                    </section>
                </CpRow>

                <CpRow className="bg-grey">
                    <section className="body">
                        <Disclaimer />
                        <section>
                            <h3 className="ft-sub-headline">
                                {intl.formatMessage({ id: 'LINK_GENERATION_SHORTEN_URL' })}
                            </h3>
                            <div className="align-center shorten-url">
                                {/* <Input size="large" value={shortUrl} className="shorten-url-input" ref={r => this.urlRef = r} readOnly /> */}
                                <div className="shorten-url-wrapper">
                                    <UnselectableInput value={shortUrl} size="large" className="shorten-url-input" />
                                    <MediaQuery minWidth={768}>
                                        {() => {
                                            return (
                                                <div className="shorten-url-controls">
                                                    <SnsSharer
                                                        link={shortUrl}
                                                        title={title}
                                                        description={description}
                                                        imageUrl={imageUrl}
                                                    />

                                                    <Copy2Clipboard
                                                        content={shortUrl}
                                                        popOverText={intl.formatMessage({ id: 'COPIED_DISCLAIMER' })}>
                                                        <Button
                                                            className="lg shorten-url-controls-main"
                                                            style={{ width: 100 }}
                                                            onClick={this.copyUrlToClipboard}>
                                                            {intl.formatMessage({ id: 'LINK_GENERATION_COPY_URL' })}
                                                        </Button>
                                                    </Copy2Clipboard>
                                                </div>
                                            );
                                        }}
                                    </MediaQuery>
                                </div>
                                <AffiliateFeeNotice />
                            </div>
                        </section>
                        <section>
                            <h3 className="ft-sub-headline">
                                {intl.formatMessage({ id: 'LINK_GENERATION_IMAGE_TEXT' })}
                            </h3>
                            <div className="code-generation-container" style={{ paddingTop: 0 }}>
                                <div className="code-generation-content">
                                    <div className="code-preview">
                                        <h2>{intl.formatMessage({ id: 'LINK_GENERATION_PREVIEW' })}</h2>
                                        <div className="code-container">
                                            <div className="align-center">
                                                <div
                                                    style={{ lineHeight: 0 }}
                                                    dangerouslySetInnerHTML={{ __html: htmlString }}></div>
                                                <div style={{ marginTop: 30 }}>
                                                    <Checkbox checked={this.state.border} onChange={this.toggleBorder}>
                                                        {intl.formatMessage({ id: 'LINK_GENERATION_BORDER' })}
                                                    </Checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="code-viewer">
                                        <HTMLCodeViewer
                                            options={codeOptions}
                                            titleTip={intl.formatMessage({ id: 'LINK_GENERATION_CODE_TIP' })}
                                            option={this.state.codeOption}
                                            html={htmlString}
                                            onOptionChange={this.onCodeFormatChange}
                                            onCopyCode={this.onCopyCode}
                                            textClassName="html-code"
                                            bottomChild={codeCopied && <AffiliateFeeNotice className="align-left" />}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(LinkGeneration)));
