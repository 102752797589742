import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, notification, Form } from 'antd';
import debounce from 'lodash/debounce';
import cx from 'classnames';
import { WorkspacePage, CpRow, HTMLCodeViewer, confirmHoc } from '../../components';
import HomeIcon from 'react-feather/dist/icons/home';
import CopyIcon from 'react-feather/dist/icons/copy';
import { DynamicWidgetSettings, DynamicWidgetPreview } from '../components';
import { TrackerContext, AffiliateFeeNotice } from '../../common';
import Disclaimer from '../components/Disclaimer/Disclaimer';
import Api from '../api';

import './WidgetInstance.less';

const CloseConfirmButton = confirmHoc(Button);

class WidgetInstance extends Component {
    static contextType = TrackerContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            datasetTypes: [],
            datasets: [],
            widgetWidth: '680',
            widgetHeight: '140',
            previewHTML: '',
            codeOption: 'js',
            codeCopied: false,
        };
        this.previewInnerContainerRef = React.createRef();
        this.previewRef = React.createRef();
        this.codeViewerRef = React.createRef();
        this.formRef = React.createRef();
        this.SettingsForm = Form.create({
            onValuesChange: debounce(
                (props, changedValues, allValues) => this.handleValuesChange(props, changedValues, allValues),
                400
            ),
        })(DynamicWidgetSettings);
    }

    get form() {
        return this.formRef.current;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentSubId !== this.props.currentSubId) {
            const { data } = this.state;
            if (data && data.widgetHTML) {
                this.handleSave();
            }
        }
    }

    componentDidMount() {
        const {
            match: { params },
            intl,
        } = this.props;

        this.setState({ loading: true }, () => {
            Api.getWidgetInstancePageInitData(params && params.instanceId)
                .then((res) => {
                    const { data: { datasetTypes, datasets, widgetInstance } = {} } = res.data;
                    this.setState({
                        datasetTypes,
                        datasets,
                        data: widgetInstance || { width: 680, height: 140 },
                    });

                    let widgetInst = { ...widgetInstance };
                    if (!widgetInstance || !widgetInstance.datasetId) {
                        widgetInst.height = this.state.widgetHeight;
                        widgetInst.width = this.state.widgetWidth;
                    }
                    this.refreshPreview(widgetInst);
                })
                .catch((err) => {
                    if (err && err.data && err.data.rMessage) {
                        notification.error({
                            message: intl.formatMessage({ id: 'ERROR' }),
                            description: err.data.rMessage,
                        });
                    } else {
                        console.error(err);
                    }
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        });
    }

    handleValuesChange = (props, changedValues, allValues) => {
        this.refreshPreview(allValues);
    };

    refreshPreview(widgetInstance = {}) {
        const { datasetId, width, height, bordered } = widgetInstance;
        const formErrors = (this.form && this.form.props && this.form.props.form.getFieldsError()) || {};

        if (datasetId && width && height && !formErrors['width'] && !formErrors['height']) {
            const { current } = this.previewRef || {};
            if (current && current.centerPreview) {
                current.centerPreview(width, height);
            }

            const { trackingCode } = this.props;
            const previewData = {
                trackingCode,
                datasetId,
                widgetName: 'carousel',
                width,
                height,
                bordered,
            };
            this.fetchPreviewHTML(previewData);
        }
    }

    fetchPreviewHTML(previewData) {
        this.setState({ loading: true }, () => {
            Api.getWidgetTemplate(previewData)
                .then((res) => {
                    this.setState({ previewHTML: res.data });
                })
                .catch((err) => {
                    const { data: { rMessage } = {} } = err;
                    if (rMessage) {
                        const { intl } = this.props;
                        notification.warn({
                            message: intl.formatMessage({ id: 'ERROR' }),
                            description: err.data.rMessage,
                        });
                    }
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        });
    }

    handleDatasetTypeChange = (datasetType) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                Api.getDatasets(datasetType)
                    .then((res) => {
                        const {
                            form: { setFieldsValue },
                        } = this.form.props;
                        setFieldsValue({ datasetId: null });
                        this.setState({
                            datasets: res.data.data,
                        });
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            }
        );
    };

    handleCancel = () => {
        const { history } = this.props;
        this.context.trackEvent({ action: 'close_widget_inst' });
        history.push('/affiliate/ws/dynamic-widgets');
    };

    handleSave = () => {
        if (this.form && this.form.props) {
            const { intl } = this.props;
            const {
                form: { validateFields },
            } = this.form.props;
            validateFields((errors, values) => {
                if (errors) {
                    return;
                }
                const widgetInstance = { ...this.state.data, widgetName: 'carousel', iframe: undefined, js: undefined, widgetHTML: undefined, ...values };
                this.setState({ loading: true }, () => {
                    this.context.trackEvent({ action: 'generate_widget_code' });
                    Api.saveWidgetInstance(widgetInstance)
                        .then((res) => {
                            const { data: widget } = res.data;
                            // notification.success({ message: intl.formatMessage({ id: 'NOTICE' }), description: `Widget [${widget.widgetInstanceName}] persisted!` });
                            this.setState(
                                {
                                    data: widget,
                                },
                                this.scrollCodeViewerIntoView
                            );
                        })
                        .catch((err) => {
                            let message = err.data && err.data.rMessage;
                            notification.warn({ message: intl.formatMessage({ id: 'ERROR' }), description: message });
                        })
                        .finally(() => {
                            this.setState({ loading: false });
                        });
                });
            });
        }
    };

    scrollCodeViewerIntoView = () => {
        const { current } = this.codeViewerRef || {};
        if (current && current.scrollIntoView) {
            current.scrollIntoView();
        }
    };

    handleCopyCode = () => {
        this.setState({ codeCopied: true });
        this.context.trackEvent({ action: 'copy_widget_code', value: this.state.codeOption });
    };

    refreshDatasets = () => {
        const {
            form: { getFieldValue },
        } = this.form.props;
        const dataType = getFieldValue('datatype');
        this.handleDatasetTypeChange(dataType); // To refresh
    };

    handleSaveDataset = (dataset) => {
        return new Promise((resolve, reject) => {
            Api.persistDataset(dataset)
                .then((res) => {
                    resolve(res);
                    this.refreshDatasets();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    handleDeleteDataset = (datasetId) => {
        return new Promise((resolve, reject) => {
            Api.deleteDataset(datasetId)
                .then((res) => {
                    resolve(res);
                    this.refreshDatasets();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    onCodeFormatChange = (e) => {
        this.setState({
            codeOption: e.target.value,
        });

        // switch (e.target.value) {
        //     case 'javascript':
        //         break;
        //     case 'iframe':
        //         break;
        //     default:
        //         break;
        // }
    };

    render() {
        const { intl } = this.props;
        // const title = params && params.instanceId ? 'Edit dynamic banner' : 'Create dynamic banner';
        const title = intl.formatMessage({ id: 'WIDGET_INST_TITLE' });
        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'HEADER_PL_DYNAMIC_BANNER' }), link: '/affiliate/ws/dynamic-widgets' },
            { title },
        ];

        const codeOptions = Api._isTargetAffiliate(this.props.affiliate.affiliateCode)
            ? [
                  { key: 'js', title: intl.formatMessage({ id: 'JS_TAG' }) },
                  { key: 'iframe', title: intl.formatMessage({ id: 'IFRAME_TAG' }) },
              ]
            : null;

        const {
            loading,
            datasetTypes,
            datasets,
            data,
            codeOption,
            widgetWidth,
            widgetHeight,
            previewHTML,
            codeCopied,
        } = this.state;
        return (
            <WorkspacePage
                breadcrumb={breadcrumb}
                withSubId={true}
                title={title}
                className="widget-instance-management"
                loading={loading}
                help={{
                    text: intl.formatMessage({ id: 'HEADER_HELP_USAGE_GUIDE_TAG' }),
                    link: '/help/tag-usage-guide',
                }}>
                <CpRow className="bg-grey">
                    <div className={cx('config-preview', { 'code-generated': data && data.widgetHTML })}>
                        <div className="config-section">
                            <h2>{intl.formatMessage({ id: 'WIDGET_INST_SETTINGS' })}</h2>
                            <this.SettingsForm
                                datasetTypes={datasetTypes}
                                datasets={datasets}
                                data={data}
                                onDatasetTypeChange={this.handleDatasetTypeChange}
                                onSaveDataset={this.handleSaveDataset}
                                onDeleteDataset={this.handleDeleteDataset}
                                wrappedComponentRef={this.formRef}
                            />
                        </div>
                        <div className="preview-section">
                            <h2 style={{ marginBottom: 24 }}>{intl.formatMessage({ id: 'WIDGET_INST_PREVIEW' })}</h2>
                            <DynamicWidgetPreview
                                ref={this.previewRef}
                                style={{ height: 460, marginBottom: 24 }}
                                padding={25}
                                widgetWidth={widgetWidth}
                                widgetHeight={widgetHeight}
                                html={previewHTML}
                            />
                            <div className="align-center button-bar">
                                <CloseConfirmButton
                                    size="large"
                                    closeFirstWhenClickOk={true}
                                    onOk={this.handleCancel}
                                    confirmOptions={{
                                        title: intl.formatMessage({ id: 'WIDGET_INST_CLOSE_CONFIRM_TITLE' }),
                                        description: intl.formatMessage({
                                            id: 'WIDGET_INST_CLOSE_CONFIRM_DESCRIPTION',
                                        }),
                                        contentAlignment: 'left',
                                    }}>
                                    {intl.formatMessage({ id: 'WIDGET_INST_CLOSE' })}
                                </CloseConfirmButton>
                                <Button size="large" type="primary" onClick={this.handleSave}>
                                    {intl.formatMessage({ id: 'WIDGET_INST_GENERATE' })}
                                </Button>
                            </div>
                        </div>
                    </div>

                    {!!(data && (data.html || data.iframe || data.js)) && (
                        <div>
                            <Disclaimer />
                            <div className="code-viewer" ref={this.codeViewerRef}>
                                <HTMLCodeViewer
                                    title={intl.formatMessage({ id: 'WIDGET_INST_CODE' })}
                                    options={codeOptions}
                                    option={codeOption}
                                    html={data && data[codeOption]}
                                    button={
                                        <React.Fragment>
                                            <CopyIcon size={16} />
                                            {intl.formatMessage({ id: 'COPY' })}
                                        </React.Fragment>
                                    }
                                    textClassName="html-code"
                                    onOptionChange={this.onCodeFormatChange}
                                    onCopyCode={this.handleCopyCode}
                                    bottomChild={codeCopied && <AffiliateFeeNotice className="align-left" />}
                                />
                            </div>
                        </div>
                    )}
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(
    withRouter(
        connect((state) => ({
            trackingCode: (state && state.affiliate && state.affiliate.affiliateCode) || '',
            currentSubId: state.subId.currentSubId,
            affiliate: state.affiliate,
        }))(WidgetInstance)
    )
);
