import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import HomeIcon from 'react-feather/dist/icons/home';
import { WorkspacePage, CpRow, LoadingImage, HTMLCodeViewer } from '../../components';
import Disclaimer from '../components/Disclaimer/Disclaimer';
import { fetchCategoryBannerInitData } from '../actions';
import { TrackerContext, AffiliateFeeNotice } from '../../common';
import Api from '../api';

import './BannerGeneration.css';

const BannerSizeList = [
    { width: 728, height: 90 },
    { width: 600, height: 900 },
    { width: 320, height: 480 },
    { width: 320, height: 100 },
    { width: 320, height: 50 },
    { width: 300, height: 250 },
    { width: 200, height: 200 },
    { width: 160, height: 600 },
    { width: 150, height: 60 },
    { width: 120, height: 60 },
];

class BannerGeneration extends Component {
    static contextType = TrackerContext;

    constructor(props) {
        super(props);
        if (Api._displaySpecialSizeBanner(props.affiliate.affiliateCode)) {
            if (BannerSizeList.findIndex((item) => item.width === 360 && item.height === 640) === -1) {
                BannerSizeList.splice(2, 0, { width: 360, height: 640 });
            }
        }
        this.sizes = matchMedia('only screen and (max-width:768px)').matches
            ? BannerSizeList.slice(0).reverse()
            : BannerSizeList.slice(0);
        this.state = {
            imageContainerHeight: null,
            size: this.sizes[0],
            selectedSizeIndex: 0,
            codeOption: 'html',
            codeCopied: false,
        };
    }

    fetchCategoryBanner = () => {
        const { fetchCategoryBannerInitData, match } = this.props;
        const { size } = this.state;
        if (fetchCategoryBannerInitData) {
            fetchCategoryBannerInitData({ datasetId: +match.params.category, size });
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.currentSubId !== this.props.currentSubId) {
            this.fetchCategoryBanner();
        }
    }

    componentDidMount() {
        this.fetchCategoryBanner();
    }

    onSizeChnage = ({ key }) => {
        const size = this.sizes[key];
        this.context.trackEvent({ action: 'pick_banner_size', value: `${size.width}_${size.height}` });

        let imageContainerHeight = size && size.height > 400 ? size.height + 40 : null;

        this.setState(
            {
                imageContainerHeight,
                size,
                selectedSizeIndex: key,
            },
            () => {
                this.fetchCategoryBanner();
            }
        );
    };

    onCopyCode = () => {
        this.setState({ codeCopied: true });
        this.context.trackEvent({ action: 'copy_banner_code', value: this.state.codeOption });
    };

    onCodeFormatChange = (e) => {
        this.setState({
            codeOption: e.target.value,
        });
    };

    render() {
        const { intl, loading, banner } = this.props;
        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'PRODUCT_BANNER' }), link: '/affiliate/ws/banner' },
            { title: intl.formatMessage({ id: 'PRODUCT_BANNER_GENERATION' }) },
        ];
        const { selectedSizeIndex, codeOption, codeCopied } = this.state;

        const codeOptions = Api._isTargetAffiliate(this.props.affiliate.affiliateCode)
            ? [
                  { key: 'js', title: intl.formatMessage({ id: 'JS_TAG' }) },
                  { key: 'iframe', title: intl.formatMessage({ id: 'IFRAME_TAG' }) },
                  { key: 'html', title: intl.formatMessage({ id: 'HTML_TAG' }) },
              ]
            : null;

        return (
            <WorkspacePage
                className="banner-generation"
                breadcrumb={breadcrumb}
                withSubId={true}
                title={intl.formatMessage({ id: 'PRODUCT_BANNER_GENERATION' })}
                subTitle={banner.datasetName}
                loading={loading}>
                <CpRow>
                    <Menu
                        mode="horizontal"
                        className="menu-horizontal"
                        selectedKeys={[`${selectedSizeIndex}`]}
                        triggerSubMenuAction="click"
                        onClick={this.onSizeChnage}>
                        {this.sizes.map((x, index) => (
                            <Menu.Item key={`${index}`}>{`${x.width} x ${x.height}`}</Menu.Item>
                        ))}
                    </Menu>
                </CpRow>
                
                <CpRow className="code-generation-container is-flex bg-grey">
                    <Disclaimer />
                    {banner && (
                        <div className="code-generation-content">
                            <div className="code-preview">
                                <h2>{intl.formatMessage({ id: 'LINK_GENERATION_PREVIEW' })}</h2>
                                <div className="code-container">
                                    <div>
                                        {banner.image && (
                                            <LoadingImage src={banner.image} alt="" style={{ width: '100%' }} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="code-viewer">
                                <HTMLCodeViewer
                                    options={codeOptions}
                                    option={codeOption}
                                    html={banner[codeOption]}
                                    onOptionChange={this.onCodeFormatChange}
                                    onCopyCode={this.onCopyCode}
                                    bottomChild={codeCopied && <AffiliateFeeNotice className="align-left" />}
                                />
                            </div>
                        </div>
                    )}
                </CpRow>
                
            </WorkspacePage>
        );
    }
}

export default injectIntl(
    withRouter(
        connect(
            (state) => ({
                staticImageCDN: state.config.staticImageCDN,
                loading: state.productlink.loading,
                banner: state.productlink.banner,
                currentSubId: state.subId.currentSubId,
                affiliate: state.affiliate,
            }),
            {
                fetchCategoryBannerInitData,
            }
        )(BannerGeneration)
    )
);
