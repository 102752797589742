import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form, Select, Input } from 'antd';
import { FilterInput, RsLabel } from '../../components';
import CustomDatasetDropdown from './CustomDatasetDropdown';
import { requiredMessage } from '../../common';

const DynamicWidgetSettings = injectIntl(
    class DynamicWidgetSettings extends Component {
        static propTypes = {
            datasetTypes: PropTypes.array,
            datasets: PropTypes.array,
            data: PropTypes.object,
            onDatasetTypeChange: PropTypes.func,
            onSaveDataset: PropTypes.func,
            onDeleteDataset: PropTypes.func,
        };

        handleDataTypeChange = (value, option) => {
            const { onDatasetTypeChange } = this.props;
            if (onDatasetTypeChange) {
                onDatasetTypeChange(value);
            }
        };

        validateSize = (rule, value, callback) => {
            if (!(value + '').endsWith('%')) {
                const { intl } = this.props;
                const size = parseInt(value, 10);
                if (size < 50 || size > 2000) {
                    callback(intl.formatMessage({ id: 'WIDGET_INST_SIZE_VALIDATION_MSG' }));
                    return;
                }
            }
            callback();
        };

        render() {
            const {
                intl,
                form: { getFieldDecorator, getFieldValue },
                datasetTypes,
                datasets,
                onSaveDataset,
                onDeleteDataset,
            } = this.props;
            let data = this.props.data || {};

            return (
                <Form layout="vertical">
                    <Form.Item label={intl.formatMessage({ id: 'WIDGET_INST_NAME' })}>
                        {getFieldDecorator('widgetInstanceName', {
                            initialValue: data.widgetInstanceName || '',
                            rules: [{ required: true, message: requiredMessage(intl, 'WIDGET_INST_NAME') }],
                        })(<Input size="large" maxLength={50} />)}
                    </Form.Item>
                    <Form.Item label={intl.formatMessage({ id: 'WIDGET_INST_DATA_TYPE' })}>
                        {getFieldDecorator('datatype', {
                            initialValue: data.dataType && data.dataType.type,
                            rules: [{ required: true, message: requiredMessage(intl, 'WIDGET_INST_DATA_TYPE') }],
                        })(
                            <Select size="large" onSelect={this.handleDataTypeChange}>
                                {(datasetTypes || []).map((x) => (
                                    <Select.Option key={x.type} value={x.type}>
                                        <RsLabel text={x} />
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={intl.formatMessage({ id: 'WIDGET_INST_DATA_SET' })}>
                        {getFieldDecorator('datasetId', {
                            initialValue: data.datasetId,
                            rules: [{ required: true, message: requiredMessage(intl, 'WIDGET_INST_DATA_SET') }],
                        })(
                            getFieldValue('datatype') === 'CONFIGURABLE' ? (
                                <CustomDatasetDropdown
                                    size="large"
                                    datasets={datasets}
                                    onSaveDataset={onSaveDataset}
                                    onDeleteDataset={onDeleteDataset}
                                />
                            ) : (
                                <Select
                                    size="large"
                                    dropdownClassName="hide-disabled-item"
                                    onChange={this.handleOnChange}>
                                    {(datasets || []).map((x) => (
                                        <Select.Option key={x.datasetId} value={x.datasetId} disabled={!x.active}>
                                            {x.datasetName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )
                        )}
                    </Form.Item>
                    <section className="widget-params">
                        <label className="param-title">{intl.formatMessage({ id: 'WIDGET_INST_PARAMETERS' })}</label>
                        <div className="param-fields">
                            <Form.Item
                                label={intl.formatMessage({ id: 'WIDGET_INST_PARAM_WIDTH' })}
                                className="inline-row"
                                colon={true}>
                                {getFieldDecorator('width', {
                                    initialValue: data.width || '680',
                                    rules: [
                                        { required: true, message: requiredMessage(intl, 'WIDGET_INST_PARAM_WIDTH') },
                                        { validator: this.validateSize },
                                    ],
                                })(
                                    <FilterInput
                                        filter={/^\d+(px|p|%)?$/}
                                        size="large"
                                        maxLength={6}
                                        autoComplete="off"
                                        onChange={this.handleOnChange}
                                        // addonAfter={<Select>
                                        //     <Select.Option value="px">px</Select.Option>
                                        //     <Select.Option value="%">%</Select.Option>
                                        // </Select>}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item
                                label={intl.formatMessage({ id: 'WIDGET_INST_PARAM_HEIGHT' })}
                                className="inline-row">
                                {getFieldDecorator('height', {
                                    initialValue: data.height || '120',
                                    rules: [
                                        { required: true, message: requiredMessage(intl, 'WIDGET_INST_PARAM_HEIGHT') },
                                        { validator: this.validateSize },
                                    ],
                                })(
                                    <FilterInput
                                        filter={/^\d+(px|p|%)?$/}
                                        size="large"
                                        maxLength={6}
                                        autoComplete="off"
                                        onChange={this.handleOnChange}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item
                                label={intl.formatMessage({ id: 'WIDGET_INST_PARAM_BORDER' })}
                                className="inline-row">
                                {getFieldDecorator('bordered', {
                                    initialValue: (data && data.bordered !== undefined ? !!data.bordered : true) + '',
                                })(
                                    <Select size="large">
                                        <Select.Option value={'true'}>
                                            {intl.formatMessage({ id: 'WIDGET_INST_WITH_BORDER' })}
                                        </Select.Option>
                                        <Select.Option value={'false'}>
                                            {intl.formatMessage({ id: 'WIDGET_INST_WITHOUT_BORDER' })}
                                        </Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </div>
                    </section>
                </Form>
            );
        }
    },
    { forwardRef: true }
);

export { DynamicWidgetSettings };
export default Form.create(DynamicWidgetSettings);
